.partner {
    width: 100px;
    text-align: center;
    margin-bottom: 25px;
}
.own-layout-aref {
    color: #1b8eb2;
    font-weight: 600;
    text-decoration: none !important;
    margin-top: 12px;
}
.ant-layout-content {
    margin: 0px 16px !important;
}
.own-layout-footer-version {
    float: left;
    font-size: 12px;
    margin-left: 10px;
}
.own-layout-footer-info {
    text-align: center;
    font-size: 12px;
}
