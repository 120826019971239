$primary: #22577a;
$success: #2ba700;
$link: #1b8eb2;
$text1: #1e1e1e;

.back-style {
    border-top: 1px solid #d9d9d9;
    padding-top: 17px;
    margin-bottom: 17px;
}
body {
    font-family: 'Anek Gujarati', sans-serif;
    font-size: 16px;

    b {
        font-weight: 700;
    }
}

#root,
.App,
.ant-layout,
.layout-main {
    height: 100%;
    background-color: white;
}

main.ant-layout-content {
    min-height: auto;
}

h1 {
    font-weight: 600;
    font-size: 38px;
    color: $primary;
    line-height: 1.2;

    @media (max-width: 767px) {
        font-size: 26px;
    }
}

h2 {
    font-weight: 600;
    font-size: 32px;
    color: $primary;
    line-height: 1.2;

    @media (max-width: 767px) {
        font-size: 20px;
        line-height: 1;
    }
}

a {
    color: $link;
}

.loader {
    &.center {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .element {
        border: 6px solid transparent;
        border-top: 6px solid $primary;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 0.7s linear infinite;

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }
}

.layout-main {
    section.ant-layout {
        .ant-layout-header {
            background-color: $primary;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 767px) {
                height: 60px;

                img {
                    max-height: 40px;
                }
            }
        }

        .ant-layout-footer {
            padding: 20px 0;
            background-color: #2c2c2c;
            color: #bababa;
            text-align: center;

            p {
                font-size: 12px;

                @media (max-width: 767px) {
                    font-size: 10px;
                }
            }

            a {
                font-size: 16px;
                font-weight: 600;
                text-decoration: underline;

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }
    }
}

.back {
    &.center {
        text-align: center;
    }

    a {
        color: $primary;
        font-size: 16px;
        font-weight: 600;
    }
}
