// helpers
$sizeUnit: rem;
$marginKey: 'm';
$paddingKey: 'p';
$separator: '-';
$sizes: (
    ('none', 0),
    ('xxs', 0.125),
    ('xs', 0.25),
    ('sm', 0.5),
    ('md', 1),
    ('lg', 2),
    ('xl', 4),
    ('xxl', 8)
);
$positions: (('t', 'top'), ('r', 'right'), ('b', 'bottom'), ('l', 'left'));

@function sizeValue($key, $value) {
    @return if($key== 'none', 0, $value + $sizeUnit) !important;
}

@each $size in $sizes {
    $sizeKey: nth($size, 1);
    $sizeValue: nth($size, 2);

    .#{$marginKey}#{$separator}#{$sizeKey} {
        margin: sizeValue($sizeKey, $sizeValue);
    }

    .#{$paddingKey}#{$separator}#{$sizeKey} {
        padding: sizeValue($sizeKey, $sizeValue);
    }

    @each $position in $positions {
        $posKey: nth($position, 1);
        $posValue: nth($position, 2);

        .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            margin-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }

        .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            padding-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }
    }
}

$primary: #7350ff;
$main-text-color: #2e2545;

.container {
    max-width: 1000px;
    margin: 0 auto;

	@media(max-width: 767px){
		padding: 0 10px;
	}
	
}

.container-s {
    max-width: 770px;
    margin: 0 auto;

	@media(max-width: 767px){
		padding: 0 10px;
	}
	
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.pointer {
    cursor: pointer;
}

.hide {
    display: none;
}

/* TRANSITIONS */
.appear-enter {
    opacity: 0;
    transform: scale(0.9);
}

.appear-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.appear-exit {
    opacity: 1;
}

.appear-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}


.capitalize{
	text-transform: capitalize;
}