// HOME
section.home {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 2rem 0;

    @media (max-width: 767px) {
        padding: 6rem 0;
    }

    h1 {
        text-align: center;
        margin-bottom: 0;
        line-height: 1.2;

        @media (max-width: 767px) {
            margin-bottom: 0.25rem;
        }
    }

    h2 {
        color: $text1;
        text-align: center;
        font-size: 20px;
        font-weight: 500;

        @media (max-width: 767px) {
            font-size: 18px;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        .ant-select {
            &:hover {
                .ant-select-selector {
                    border-right-width: 2px !important;
                }
            }

            .ant-select-selector {
                border: 2px solid $primary;
                height: auto;
                border-radius: 6px;
                padding: 8px 10px 6px 20px;
                font-size: 16px;
                font-weight: 400;
            }

            .ant-select-selection-search-input {
                height: 100%;
                padding-left: 10px;
            }
        }
    }

    .ant-radio-wrapper {
        font-size: 18px !important;
        font-weight: 400;

        @media (max-width: 767px) {
            font-size: 16px !important;
        }

        .ant-radio {
            position: relative;
            top: 2px;
        }
    }

    .disclaimer {
        text-align: center;
        max-width: 600px;
        margin: 0 auto;
        margin-top: 2rem;
        font-size: 15px;

        b {
            font-weight: 600;
        }
    }
}

// RESPONSE
section.response {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 3rem 0;

    .header {
        display: flex;
        margin-bottom: 0.5rem;

        > div {
            flex-basis: 50%;
            flex-shrink: 0;
        }

        .left {
            margin-right: 1rem;
        }

        .label {
            font-size: 16px;
            line-height: 1;
            margin-bottom: 0.25rem;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }

    .body {
        @media (max-width: 767px) {
            font-size: 14px;
        }

        .options {
            .item {
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                }
                margin-bottom: 1.5rem;

                p.problem {
                    display: flex;
                    margin-bottom: 0;

                    .icon {
                        margin-right: 0.5rem;
                        position: relative;
                        top: -1px;
                    }

                    & + p.problem {
                        margin-top: 0.5rem;
                    }

                    a {
                        color: $primary;
                        text-decoration: underline;
                        font-weight: 600;
                    }
                }
            }

            p.desc {
                padding-left: 21px;

                @media (max-width: 767px) {
                    margin-top: 0.25rem;
                }
            }
        }
    }

    .footer {
        text-align: center;
        margin-top: 3rem;

        @media (max-width: 767px) {
            margin-top: 2rem;
        }

        .question {
            // border-bottom: 1px solid #22577a24;
            margin-bottom: 1rem;

            h3 {
                font-weight: 600;
            }

            div {
                margin: 0 auto;
                display: inline-flex;
                gap: 1rem;
            }
        }

        button {
            margin-bottom: 1rem;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }
}

// FAQS
section.faqs {
    padding: 75px 0;

    h1 {
        text-align: center;
    }

    .faqsContainer {
        margin: 0 auto;
        margin-bottom: 2rem;
        max-width: 700px;

        .ant-collapse-item {
            &:not(:first-child) {
                border-top: 1px solid #ebebeb;
            }
        }

        .ant-collapse-header {
            font-weight: 600;
            font-size: 18px;
            padding: 24px 16px;
            padding-left: 16px;
        }

        .ant-collapse-content-box {
            padding-top: 0 !important;

            p {
                color: #686868;
                font-size: 17px;
                font-weight: 500;
            }
        }

        .ant-collapse-arrow {
            right: 0;
            left: initial !important;
        }
    }
}

// SUCCESS
section.success {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 3rem 0;

    .container-s {
        width: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    img.success {
        width: 200px;
        margin-bottom: 2rem;
    }

    h2 {
        margin-bottom: 0.5rem;
    }

    .footer {
        border-top: 1px solid #22577a24;
        margin-top: 1rem;
        width: 100%;
        padding-top: 1rem;
    }
}

// SUCCESS
section.feedback {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 3rem 0;

    .container-s {
        width: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer {
        width: 100%;
        padding-top: 1rem;
    }
}

// FORM FEEDBACK
section.formFeedback {
    h3 {
        color: $primary;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        max-width: 80%;
        margin: 0 auto;
        line-height: 1.1;
    }

    .ant-form-item-label {
        label {
            color: #5a5a5a;
        }
    }

    .ant-form-item-control-input input {
        margin-top: 1px;
        border-radius: 6px;
        height: 44px;
    }

    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        margin-top: 5px;

        .ant-checkbox-wrapper {
            display: flex;
            margin-left: 0;
            font-size: 16px;
            margin-bottom: 1rem;

            @media (max-width: 767px) {
                font-size: 14px;
                margin-bottom: 0.5rem;
            }

            .ant-checkbox {
                padding-top: 4px;
                margin-right: 4px;

                &:hover {
                    &::after {
                        visibility: hidden;
                    }
                }
            }
        }
    }

    .ant-radio-group {
        .ant-radio-wrapper {
            font-size: 16px !important;

            @media (max-width: 767px) {
                font-size: 14px !important;
            }
        }
    }

    .ant-form-item-label {
        padding-bottom: 0;
    }

    button {
        width: 100%;
    }
}

// form
.ant-select-item {
    padding-top: 6px;
    padding-bottom: 6px;
    height: auto;

    .ant-select-item-option-content {
        font-size: 15px;
    }
}

.ant-radio-wrapper {
    .ant-radio {
        position: relative;
        top: -6px;
    }
}

// button
.ant-btn {
    padding: 0.5rem 2rem;
    height: auto;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 500;
}

// modal
.ant-modal {
    top: 3%;

    .ant-modal-body {
        padding: 2rem;

        @media (max-width: 767px) {
            padding: 1.5rem 1rem;
        }
    }

    .ant-modal-content {
        border-radius: 8px;
    }
}

.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.8);
}

// message
.ant-notification-notice {
    padding-top: 1.5rem;
}
.ant-notification-notice-message {
    font-weight: 600;
    line-height: 1.2;
}
