.own-response li::marker {
    /* list-style-type: '👉'; */
    list-style: disc;
    font-size: 24px;
    color: #2ba700;
}

.own-response li,
.own-response-block li {
    line-height: 24px;
    margin-left: -17px;
}

.own-response li::marker {
    /* list-style-type: '👉'; */
    list-style: disc;
    font-size: 24px;
    color: #2ba700;
}

.own-response-block li::marker {
    /* list-style-type: '👉'; */
    list-style: disc;
    font-size: 24px;
    color: #bfbfbf;
}

.own-global-convenio {
    margin: 10px auto;
}
.own-home-link {
    color: #1b8eb2;
    font-weight: 600;
}
